.tmh-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    text-decoration: none;

    @media (max-width: 750px) {
        gap: 0px;
    }

    .header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 100%;
        width: 260px;
        text-align: center;

        @media (max-width: 750px) {
            width: 206px;
            font-size: 30px;
        }
    }

    .header-logo {
        width: 70px;
        height: 70px;

        @media (max-width: 750px) {
            width: 56px;
            height: 56px;
        }
    }
}