.landing-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 7vh;

    .label-1 {
        margin-top: 30px;
        width: 292px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 134%;

        @media (max-width: 750px) {
            margin-top: 20px;
            width: 258px;
        }
    }

    .label-2 {
        margin-top: 17px;
        width: 262px;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 120%;
        text-align: center;

        @media (max-width: 750px) {
            margin-top: 8px;
            font-size: 15px;
        }
    }

    .platform-block {
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 292px;
        height: 171px;
        background: #0C0C0CCC;
        border: 1px solid #383838CC;
        backdrop-filter: blur(20px);
        border-radius: 10px;

        @media (max-width: 750px) {
            width: 250px;
            height: 151px;
        }

        .platform-logo {
            background: none;
            border: none;
            width: 184px;
            height: 55px;
            margin-bottom: 11px;
            margin-top: 11px;
            cursor: pointer;

            &:disabled {
                cursor: default;
            }
        }

        .label-3 {
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            opacity: 0.8;
        }
    }

    .roku-remote-block {
        cursor: pointer;
        margin-top: 8px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 292px;
        height: 98px;
        background: #0C0C0CCC;
        border: 1px solid #383838CC;
        backdrop-filter: blur(20px);
        border-radius: 10px;

        @media (max-width: 750px) {
            width: 250px;
            height: 80px;
            margin-top: 5px;
        }

        @media (max-width: 50px) {
            margin-top: 5px;
        }

        .logo {
            background: none;
            border: none;
            width: 44px;
            height: 44px;
            margin: 0px 11px;
        }

        .label-3 {
            width: 200px;
            font-weight: 300;
            font-size: 15px;
            line-height: 120%;
            cursor: pointer;

            @media (max-width: 750px) {
                font-size: 13px;
            }
        }
    }
}