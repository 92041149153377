.extension-thanks-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20vh;
    text-align: center;

    .label-1 {
        margin-top: 30px;
        max-width: 744px;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 200%;

        @media (max-width: 750px) {
            margin-top: 20px;
            width: 80%;
            font-size: 15px;
        }
    }

    .label-2 {
        font-style: normal;
        font-weight: 800;
        font-size: 25px;
        line-height: 50px;

        @media (max-width: 750px) {
            margin-top: 8px;
            width: 80%;
            font-size: 15px;
        }
    }

    .label-2-container {
        display: flex;
        align-items: center;
        margin-top: 20px;
      }

    .tick-img {
        margin-left: 15px;
        width: 50px;
        height: 50px;
    }

    .get-button {
        margin-top: 34px;
        margin-bottom: 34px;
        width: 295px;
        height: 65px;
        border-radius: 10px;
        border: 2px solid #33E13A;
        background-color: #33E13A00;
        cursor: pointer;
        font-size: 20px;
        text-align: center;
        margin-bottom: 10vh;

        @media (max-width: 750px) {
            width: 250px;
            height: 64px;
        }
    }
}