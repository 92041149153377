.modal-nonus {
    display: block;
    position: relative;
    font-size: 30px;
    width: 1044px;
    height: 467px;
    background: #000000;
    border: 6px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 0px;

    @media (max-width: 1400px) {
        width: 90vw;
    }

    @media (max-width: 750px) {
        width: 100vw;
        height: 100vh;
    }

    .content {
        margin-top: 104px;
        text-align: center;

        @media (max-width: 750px) {
            margin-top: 200px;
        }

        .description {
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 30px;
            line-height: 133%;
            text-align: center;
            width: 908px;
            margin-left: 69px;

            @media (max-width: 1120px) {
                margin: 0 5% 0 5%;
                width: 90%;
                font-size: 25px;
            }

            @media (max-width: 750px) {
                margin: 0 5% 0 5%;
                width: 90%;
                font-size: 18px;
                line-height: 150%;
                margin-top: 5%;
            }
        }

        .title {
            margin-bottom: 30px;
            font-weight: 700;
            font-size: 30px;
            line-height: 133%;
            text-align: center;

            @media (max-width: 1120px) {
                margin: 0 5% 0 5%;
                font-size: 25px;
            }

            @media (max-width: 750px) {
                font-size: 18px;
                line-height: 150%;
                margin-top: 5%;
            }
        }

        .proceed-button {
            margin-top: 50px;
            cursor: pointer;
            width: 270px;
            height: 70px;
            background: #FF0000;
            border-radius: 100px;
            font-weight: 700;
            font-size: 40px;
            line-height: 175%;
            border: none;

            @media (max-width: 750px) {
                width: 165px;
                height: 53px;
                margin-top: 30px;
                font-size: 16px;
            }
        }

        .close-button {
            background-color: #FF0000;
            margin-top: 50px;
            cursor: pointer;
            width: 322px;
            height: 70px;
            text-decoration: underline;
            border-radius: 100px;
            font-weight: 700;
            font-size: 40px;
            line-height: 175%;
            border: none;

            @media (max-width: 750px) {
                width: 160px;
                height: 30px;
                margin-top: 30px;
                font-size: 16px;
            }
        }
    }
}

.wrapper-banner {
    background: url("./banner-background.png");
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 100%;
    background-color: #cccccc;
    /* Used if the image is unavailable */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
}

.navbar-banner {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}