.extension-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 7vh;

    .label-1 {
        margin-top: 30px;
        max-width: 744px;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 134%;

        @media (max-width: 750px) {
            margin-top: 20px;
            width: 80%;
        }
    }

    .label-2 {
        margin-top: 17px;
        max-width: 744px;
        width: 100%;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 120%;
        text-align: left;

        @media (max-width: 750px) {
            margin-top: 8px;
            width: 80%;
        }
    }

    .sample-block {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 64px;

        img {
            width: 418px;
            height: 272px;
        }


        @media (max-width: 750px) {
            flex-direction: column;
            gap: 10px;
            img {
                width: 80%;
                height: fit-content;
            }
        }
    }
    
    .get-button {
        margin-top: 34px;
        margin-bottom: 34px;
        width: 292px;
        height: 74px;
        border-radius: 10px;
        border: 1px solid #383838;
        background-color: #33E13A;
        cursor: pointer;
        font-size: 20px;
        text-align: center;

        @media (max-width: 750px) {
            width: 250px;
            height: 64px;
        }
    }
}